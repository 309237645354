import React from 'react';
import { Image } from 'react-native';
import Checkbox from '@material-ui/core/Checkbox';
import MaUTable from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableToolbar from './TableToolbar';
import matchSorter from 'match-sorter';
import {
    useGlobalFilter,
    useSortBy,
    useTable,
    usePagination,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
} from 'react-table';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Text, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { GeneralRoleType } from '../constants/WorkflowEnums';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Box, Flex, Button } from './common';

const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:before': {
            borderBottomColor: 'transparent',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'rgb(224, 224, 224)',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'transparent',
        },
        '& .MuiInput-underline.Mui-error:after': {
            transform: 'scaleX(1)',
            borderBottomColor: '#f44336',
        },

        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
        '& input:focus': {
            backgroundColor: '#fff',
        },
    },
})(TextField);

const CSSTableRow = withStyles({
    root: {
        '&:hover td': {
            backgroundColor: '#f1f2f6',
        },
    },
})(TableRow);

// @ts-ignore
const StyledTableCell = withStyles({
    head: {
        backgroundColor: '#DDF4FA',
        color: '#0b3074',
        fontFamily: 'Poppins',
    },
    body: {
        fontSize: 14,
    },
})(TableCell);

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <Checkbox color="primary" ref={resolvedRef} {...rest} />
        </>
    );
});

const inputStyle = {
    padding: 0,
    margin: 0,
    border: 0,
    background: 'transparent',
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {},
}));

// Define a default UI for filtering
function DefaultColumnFilter({
    ApolloPartnersRoleType,
    column: { filterValue, preFilteredRows, setFilter, ...rest },
}) {
    const count = preFilteredRows.length;
    if (rest.Header == 'Partner Role') {
        return (
            <select
                style={{ width: '100%', minWidth: '100px' }}
                value={filterValue != null ? filterValue : ''}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}>
                <option key="" value="">
                    Choose Role...
                </option>
                {ApolloPartnersRoleType &&
                    ApolloPartnersRoleType.map((role, index) => (
                        <option key={role.Id} value={role.Id}>
                            {role.Description}
                        </option>
                    ))}
            </select>
        );
    } else {
        return (
            <input
                value={filterValue || ''}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        );
    }
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Create an editable cell renderer
const EditableCell = ({
    cell,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    editable,
    ApolloPartnersRoleType,
    dropdownRoles,
    removePartner,
    formData,
    data,
    existingRolesInTable,
}) => {
    const onChange = (e) => {
        if (e.target.type === 'checkbox') {
            setValue(e.target.checked);
            updateMyData(index, row, id, e.target.checked);
        } else {
            setValue(e.target.value);
        }
    };
    const classes = useStyles();

    const SelectHeader = cell.column.Header;

    const defaultValidate = (val) => ({
        error: val && val.length <= 0,
        helperText: `${column.Header} is required`,
    });

    let { value: initialValue, column, row } = cell;
    let { multiSelect, inputType = 'text', validate = defaultValidate } = column;

    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);
    const [error, setError] = React.useState(false);
    const [focus, setFocus] = React.useState(false);
    // We'll only update the external data when the input is blurred
    const onBlur = (e) => {
        console.log('eeeeee', e.target.value);
        setError(validate(e.target.value));
        setFocus(false);
        updateMyData(index, row, id, e.target.value);
    };

    const onRemovePartnerChange = (value) => {
        console.log('testhereonchange');
        console.log(!value);
        updateMyData(index, row, id, !value);
        setValue(!value);
    };

    const getFontColor = () => {
        return row.original.PartnersOperation == 'Remove' || value == 'Remove'
            ? 'red'
            : row.original.PartnersOperation == 'Add' ||
              value == 'Add' ||
              row.original.PartnersOperation == 'Edit' ||
              value == 'Edit'
            ? 'green'
            : '#000000de';
    };

    const deletePartnerHandler = (row) => {
        removePartner(row);
    };

    const getPartnerRole = (partnerValue) => {
        let roleValue = null;
        if (ApolloPartnersRoleType)
            ApolloPartnersRoleType.some((roleValues) => {
                if (roleValues.Id == partnerValue) {
                    roleValue = roleValues.Description;
                    return true;
                }
            });
        return roleValue;
    };

    if (SelectHeader === 'Partner Operation') {
        return (
            <>
                <Text style={{ color: getFontColor() }}>
                    {row.original.PartnersOperation
                        ? row.original.PartnersOperation + '  '
                        : value
                        ? value
                        : ''}
                </Text>{' '}
                {row && row.original.newRow && (
                    <Tooltip title="Delete" aria-label="delete">
                        {/* <IconButton onClick={() => deletePartnerHandler(row)}> */}
                        {/* <Image
                                resizeMode="contain"
                                style={{ height: 18, width: 18 }}
                                source={require('../../assets/icons/trashIcon.png')}
                            /> */}
                        <Text
                            style={{ cursor: 'pointer' }}
                            onClick={() => deletePartnerHandler(row)}>
                            | Cancel
                        </Text>
                        {/* </IconButton> */}
                    </Tooltip>
                )}
                {/* {row && row.original.newRow && (
                    <Text style={{ color: getFontColor() }}>{value}</Text>
                )}
                {row && row.original.UnPartner && (
                    <Text style={{ color: 'red' }}>Remove</Text>
                )} */}
            </>
        );
    }

    if (SelectHeader === 'Partner Role') {
        const RoleTypeValidValues = dropdownRoles;
        // .filter(role=> !existingRolesInTable.includes(role.Id) );
        // const difference = myArray.filter( x => !toRemove.has(x) );

        // console.log('existin---', existingRolesInTable, RoleTypeValidValues, value);
        return (
            <>
                {row && row.original.IsEditable != false && row.original.newRow ? (
                    <Select
                        value={value != null ? value : ''}
                        defaultValue={1}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={() => setFocus(true)}
                        disabled={
                            !row.original.WorkflowOrCustomerNumber || !row.original.newRow
                        }
                        style={{ width: '100%', color: getFontColor() }}>
                        {RoleTypeValidValues &&
                            RoleTypeValidValues.map((data, index) => {
                                return (
                                    <option key={data.Id} value={data.Id}>
                                        {data.Description}
                                    </option>
                                );
                            })}
                    </Select>
                ) : (
                    <Text style={{ color: getFontColor() }}>{getPartnerRole(value)}</Text>
                )}
            </>
        );
    }

    if (SelectHeader === 'Remove Partner') {
        return (
            <>
                {row && row.original.IsEditable != false && !row.original.newRow ? (
                    <>
                        {!value ? (
                            <div style={{ flexDirection: 'row' }}>
                                <Image
                                    resizeMode="contain"
                                    style={{
                                        height: 18,
                                        width: 18,
                                        cursor: 'pointer',
                                    }}
                                    id={'RemovePartner'}
                                    source={require('../../assets/icons/trashIcon.png')}
                                    onClick={() => {
                                        onRemovePartnerChange(value);
                                    }}
                                />
                                {/* <Text>Remove</Text> */}
                            </div>
                        ) : (
                            <>
                                <MaterialCommunityIcons
                                    name="undo-variant"
                                    size={24}
                                    color="#10206c"
                                    style={{ cursor: 'pointer' }}
                                    id={'RemovePartner'}
                                    onClick={() => {
                                        onRemovePartnerChange(value);
                                    }}
                                />
                                {/* <Text>Undo</Text> */}
                            </>
                        )}
                    </>
                ) : (
                    <Text style={{ color: getFontColor(), textTransform: 'capitalize' }}>
                        {value.toString()}
                    </Text>
                )}
            </>
        );
    }

    if (SelectHeader === 'Customer Or Workflow Number') {
        return (
            <Text style={{ color: getFontColor() }}>
                {formData.fetchedCustomerId || row.original.WorkflowOrCustomerNumber}
            </Text>
        );
    }

    // If the initialValue is changed externall, sync it up with our state
    React.useEffect(() => {
        setError(validate(initialValue));
        setValue(initialValue);
    }, [initialValue]);

    if (!editable) {
        return `${initialValue ? initialValue : ''}`;
    }
    if (SelectHeader === 'Partner Number') {
        return (
            <div
                className={classes.root}
                onPaste={(e) => {
                    if (focus) e.stopPropagation();
                }}>
                <CssTextField
                    error={error.error}
                    helperText={error.error ? error.helperText : ''}
                    margin="dense"
                    type={inputType}
                    fullWidth
                    value={value || row.original.CustomerNumberOrWorkflowNumber}
                    onChange={onChange}
                    placeholder={error.error ? null : `Enter ${column.Header}`}
                    onBlur={onBlur}
                    inputProps={{
                        style: {
                            color:
                                row.original.UnPartner === true
                                    ? 'red'
                                    : row.original.newRow
                                    ? 'green'
                                    : '#000000de',
                        },
                    }}
                />
            </div>
        );
    }
    return (
        <div
            className={classes.root}
            onPaste={(e) => {
                if (focus) e.stopPropagation();
            }}>
            <CssTextField
                error={error.error}
                helperText={error.error ? error.helperText : ''}
                margin="dense"
                type={inputType}
                fullWidth
                value={value}
                onChange={onChange}
                placeholder={error.error ? null : `Enter ${column.Header}`}
                onBlur={onBlur}
                inputProps={{
                    style: {
                        color:
                            row.original.UnPartner === true
                                ? 'red'
                                : row.original.newRow
                                ? 'green'
                                : '#000000de',
                    },
                }}
            />
        </div>
    );
};

EditableCell.propTypes = {
    cell: PropTypes.shape({
        value: PropTypes.any.isRequired,
    }),
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
    }),
    column: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }),
    updateMyData: PropTypes.func.isRequired,
    // ApolloPartnersRoleType: PropTypes.shape({
    //     value: PropTypes.any.isRequired,
    // }),
};

function GetIEVersion() {
    var sAgent = window.navigator.userAgent;
    var Idx = sAgent.indexOf('MSIE');

    // If IE, return version number.
    if (Idx > 0) return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));
    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
    else return 0; //It is not IE
}

// Set our editable cell renderer as the default Cell renderer

const CreateJdeEnhancedTable = ({
    columns,
    data,
    setData,
    updateMyData,
    openModal,
    selectRowHandler,
    skipPageReset,
    addNewPartnerData,
    removePartner,
    ApolloPartnersRoleType,
    dropdownRoles,
    showWarning,
    existingRolesInTable,
    formData,
}) => {
    var isIE = GetIEVersion() > 0 ? true : false;
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
            // And also our default editable cell
            Cell: EditableCell,
        }),
        []
    );

    React.useEffect(() => setPageSize(1000), []);
    const [addButton, setAddButton] = React.useState(false);
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        selectedFlatRows,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: {
            pageIndex,
            pageSize,
            selectedRowIds,
            globalFilter,
            sortBy,
            groupBy,
            expanded,
            filters,
        },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            autoResetPage: !skipPageReset,
            autoResetSelectedRows: !skipPageReset,
            disableMultiSort: true,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            openModal,
            updateMyData,
            selectRowHandler,
            ApolloPartnersRoleType,
            dropdownRoles,
            existingRolesInTable,
            showWarning,
            removePartner,
            formData,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
        // (hooks) => {
        //     !isIE &&
        //         hooks.allColumns.push((columns) => [
        //             // Let's make a column for selection
        //             {
        //                 id: 'selection',
        //                 // The header can use the table's getToggleAllRowsSelectedProps method
        //                 // to render a checkbox.  Pagination is a problem since this will select all
        //                 // rows even though not all rows are on the current page.  The solution should
        //                 // be server side pagination.  For one, the clients should not download all
        //                 // rows in most cases.  The client should only download data for the current page.
        //                 // In that case, getToggleAllRowsSelectedProps works fine.
        //                 Header: ({ getToggleAllRowsSelectedProps }) => (
        //                     <div
        //                         style={{
        //                             display: 'flex',
        //                             justifyContent: 'center',
        //                             flex: 1,
        //                             width: '100%',
        //                             padding: 0,
        //                         }}>
        //                         {/* <IndeterminateCheckbox
        //                             {...getToggleAllRowsSelectedProps()}
        //                         /> */}
        //                         <Tooltip title="Add Partners" aria-label="add">
        //                             <IconButton
        //                                 onClick={() => {
        //                                     if (Object.keys(formData).length > 0)
        //                                         addPartnersHandler({
        //                                             WorkflowOrCustomerNumber: '',
        //                                             PartnerCustomerNumber: '',
        //                                             IsEditable: true,
        //                                             PartnerFunctionRole: '',
        //                                             PartnerFunctionTypeId: '',
        //                                             MdmNumber: '',
        //                                             PartnerCustomerName: '',
        //                                             PartnerCustomerAddress: '',
        //                                             IsDefaultPartner: false,
        //                                             UnPartner: false,
        //                                             newRow: true,
        //                                             PartnersOperation: 'Add',
        //                                         });
        //                                 }}
        //                                 disabled={addButton}>
        //                                 <Image
        //                                     resizeMode="contain"
        //                                     style={{ height: 18, width: 18 }}
        //                                     source={require('../../assets/icons/addIcon.png')}
        //                                 />
        //                             </IconButton>
        //                         </Tooltip>

        //                         <Tooltip
        //                             title="Delete"
        //                             aria-label="delete"
        //                             style={{ display: 'none' }}>
        //                             <IconButton onClick={() => deletePartnerHandler()}>
        //                                 <Image
        //                                     resizeMode="contain"
        //                                     style={{ height: 18, width: 18 }}
        //                                     source={require('../../assets/icons/trashIcon.png')}
        //                                 />
        //                             </IconButton>
        //                         </Tooltip>
        //                     </div>
        //                 ),
        //                 // The cell can use the individual row's getToggleRowSelectedProps method
        //                 // to the render a checkbox
        //                 Cell: ({ row }) => (
        //                     <div>
        //                         <IndeterminateCheckbox
        //                             {...row.getToggleRowSelectedProps()}
        //                             row={row}
        //                             disabled={row.original.IsEditable === false}
        //                             onPress={() =>
        //                                 selectRowHandler(selectedFlatRows, selectedRowIds)
        //                             }
        //                             style={{ display: 'none' }}
        //                         />
        //                     </div>
        //                 ),
        //             },
        //             ...columns,
        //         ]);
        // }
    );

    React.useEffect(() => {
        selectRowHandler(selectedFlatRows, selectedRowIds);
    }, [selectedFlatRows]);

    const addPartnersHandler = (partner) => {
        // event.preventDefault();
        addNewPartnerData(partner);
        setAddButton(true);
    };

    const deletePartnerHandler = () => {
        removePartner();
    };

    const handleChangePage = (event, newPage) => {
        gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(Number(event.target.value));
    };

    const removeByIndexs = (array, indexs) => array.filter((_, i) => !indexs.includes(i));

    const deleteUserHandler = (event) => {
        event.preventDefault();
        const newData = removeByIndexs(
            data,
            Object.keys(selectedRowIds).map((x) => parseInt(x, 10))
        );
        setData(newData);
    };

    const addUserHandler = (user) => {
        const newData = [user].concat(data);
        setData(newData);
    };

    const getCellFontColor = (row) => {
        return row.original.PartnersOperation == 'Remove'
            ? 'red'
            : row.original.PartnersOperation == 'Add' ||
              row.original.PartnersOperation == 'Edit'
            ? 'green'
            : '#000000de';
    };

    // Render the UI for your table
    return (
        <>
            <TableContainer
                style={{
                    border: '1.5px solid #234385',
                    overflow: 'auto',
                    maxHeight: '70vh',
                }}>
                {/*<TableToolbar*/}
                {/*  numSelected={Object.keys(selectedRowIds).length}*/}
                {/*  deleteUserHandler={deleteUserHandler}*/}
                {/*  addUserHandler={addUserHandler}*/}
                {/*  preGlobalFilteredRows={preGlobalFilteredRows}*/}
                {/*  setGlobalFilter={setGlobalFilter}*/}
                {/*  globalFilter={globalFilter}*/}
                {/*/>*/}
                <MaUTable {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <StyledTableCell
                                        key={column.id}
                                        style={{
                                            borderBottom:
                                                '1px solid rgba(130, 214, 216, 1)',
                                            borderLeft:
                                                '1px solid rgba(130, 214, 216, 1)',
                                            padding: '7px 5px 7px 5px',
                                        }}>
                                        <div
                                            {...(column.id === 'selection'
                                                ? column.getHeaderProps()
                                                : column.getHeaderProps(
                                                      column.getSortByToggleProps()
                                                  ))}>
                                            {column.render('Header')}
                                            {column.id !== 'selection' ? (
                                                <TableSortLabel
                                                    active={column.isSorted}
                                                    // react-table has a unsorted state which is not treated here
                                                    direction={
                                                        column.isSortedDesc
                                                            ? 'desc'
                                                            : 'asc'
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                        <div>
                                            {column.canFilter
                                                ? column.render('Filter')
                                                : null}
                                        </div>
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    {data.length == 0 && (
                        <TableBody>
                            <TableCell
                                colSpan={9}
                                style={{
                                    background: 'white',
                                    color:
                                        Object.keys(formData).length > 0
                                            ? 'red'
                                            : '#10206C',
                                }}>
                                {Object.keys(formData).length > 0
                                    ? 'Data not found for searched filter'
                                    : 'Search Data to Display'}
                            </TableCell>
                        </TableBody>
                    )}
                    <TableBody>
                        {page.map((row, i) => {
                            prepareRow(row);

                            return (
                                <CSSTableRow
                                    style={{
                                        backgroundColor:
                                            i % 2 === 0 ? '#ffffff' : '#f6f6f6',
                                        textAlign: 'center',
                                        // borderRight: '2px solid rgba(224, 224, 224, 1)',
                                        // borderBottom: '2px solid rgba(224, 224, 224, 1)'
                                    }}
                                    {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                style={{
                                                    borderLeft:
                                                        '1px solid rgba(130, 214, 216, 1)',
                                                    borderRight:
                                                        '1px solid rgba(130, 214, 216, 1)',
                                                    backgroundColor: cell.row.isSelected
                                                        ? '#f1f2f6'
                                                        : i % 2 === 0
                                                        ? '#ffffff'
                                                        : '#f5f5f5',
                                                    padding: '10px',
                                                    fontFamily: 'Poppins',
                                                    color: getCellFontColor(cell.row),
                                                }}>
                                                {cell.render('Cell', {
                                                    editable:
                                                        cell.row.original.newRow &&
                                                        cell.row.original.IsEditable !=
                                                            false
                                                            ? cell.row.original.newRow &&
                                                              cell.column.editable
                                                            : false,
                                                })}
                                            </TableCell>
                                        );
                                    })}
                                </CSSTableRow>
                            );
                        })}
                    </TableBody>
                </MaUTable>
            </TableContainer>

            {/*<TableFooter>*/}
            {/*<View style={{}}>*/}
            <MaUTable style={{}}>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={1}>
                            {showWarning && (
                                <Text style={{ color: 'red' }}>
                                    Duplicate/Redundant data are not allowed to be
                                    submitted and are removed automatically from the grid.
                                </Text>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ display: 'none' }}>
                        {/* <TablePagination
                            rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: 'All', value: data.length },
                            ]}
                            colSpan={4}
                            align={'left'}
                            count={data.length}
                            rowsPerPage={pageSize}
                            page={pageIndex}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /> */}
                    </TableRow>
                </TableFooter>
            </MaUTable>

            {/*</View>*/}
            {/*</TableRow>*/}
            {/*</TableFooter>*/}
        </>
    );
};

CreateJdeEnhancedTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    updateMyData: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    skipPageReset: PropTypes.bool.isRequired,
    openModal: PropTypes.func.isRequired,
};

export default CreateJdeEnhancedTable;
